const isTokenExpired = (token) => {
  if (!token) return true;

  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const currentTime = Date.now() / 1000;

  return decodedToken.exp < currentTime; // Vérifie si l'expiration est passée
};

const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.refresh_token;
};
const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.id_token;
};
const updateLocalAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem("user"));
  user.refresh_token = token;
  localStorage.setItem("user", JSON.stringify(user));
};

const setCurrentUser = (currentUser) => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user?.refresh_token) {
    localStorage.setItem("currentUser ", JSON.stringify(currentUser));
  }
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("currentUser"));
};

const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};
const removeUser = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("currentUser");
};
const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  setCurrentUser,
  getCurrentUser,
  isTokenExpired,
};
export default TokenService;
