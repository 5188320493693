import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Input,
  Form,
  NavItem,
  CardBody,
  Label,
  Modal,
  Button as ReactstrapButton,
} from "reactstrap";
import Select from "react-select";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Button, message, Skeleton, Rate } from "antd";
import classnames from "classnames";
import AddExperienceForm from "./CvForm/ExperienceForm";
import AddEducationForm from "./CvForm/TrainingForm";
import AddSkillForm from "./CvForm/SkillForm";
import AddLanguageForm from "./CvForm/LanguageForm";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCandidateExperiences,
  deleteCandidateExperience,
  fetchCandidateEducations,
  deleteCandidateEducation,
  fetchCandidateSkills,
  deleteCandidateSkill,
  deleteCandidateLanguage,
  fetchCandidateLanguages,
  fetchCandidateInfoPerso,
  updateCandidateInfoPerso,
  uploadProfilePicture,
} from "../../../redux/profile/action";
//Images Import
// import userImage2 from "../../../assets/images/user/img-02.jpg";
import { Link } from "react-router-dom";

const RightSideContent = () => {
  const languageLevelMap = {
    BEGINNER: 1,
    INTERMEDIARY: 2,
    EXPERT: 3,
  };
  const [profileImage, setProfileImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [value, setValue] = React.useState(50);

  const dispatch = useDispatch();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(URL.createObjectURL(file));
      setSelectedImageFile(file);
    }
  };

  const {
    allExperiences,
    allEducations,
    allSkills,
    allLanguages,
    loading,
    error,
    educationLoading,
    educationError,
    skillLoading,
    skillError,
    languageLoading,
    languageError,
    infoPersoDetails,
    infoPersoLoading,
    // infoPersoError,
  } = useSelector((state) => state.profileReducer);

  const [infoPersoFormData, setInfoPersoFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    birthday: "",
    driverLicense: "",
    street: "",
    city: "",
    country: "",
    zipCode: "",
    urlFacebook: "",
    urlTwitter: "",
    urlLinkedIn: "",
  });

  useEffect(() => {
    dispatch(fetchCandidateExperiences());
    dispatch(fetchCandidateEducations());
    dispatch(fetchCandidateSkills());
    dispatch(fetchCandidateLanguages());
    dispatch(fetchCandidateInfoPerso());
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    if (infoPersoDetails) {
      setInfoPersoFormData({
        firstName: infoPersoDetails.data.user.firstName || "",
        lastName: infoPersoDetails.data.user.lastName || "",
        whatsapp: infoPersoDetails.data.whatsapp || "",
        birthday: infoPersoDetails.data.birthday || "",
        summary: infoPersoDetails.data.summary || "",
        urlPicture: infoPersoDetails.data.user.imageUrl || "",
        driverLicense: infoPersoDetails.data.driverLicense, // Utiliser le tableau pour driverLicense
        street: infoPersoDetails.data.address?.street || "",
        city: infoPersoDetails.data.address?.city || "",
        country: infoPersoDetails.data.address?.country || "",
        zipCode: infoPersoDetails.data.address?.zipCode || "",
        urlFacebook: infoPersoDetails.data.urlFacebook || "",
        urlTwitter: infoPersoDetails.data.urlTwitter || "",
        urlLinkedIn: infoPersoDetails.data.urlLinkedIn || "",
      });
    }
  }, [infoPersoDetails]);

  const handleChangeInfoPerso = (e) => {
    const { id, value } = e.target;
    setInfoPersoFormData({ ...infoPersoFormData, [id]: value });
  };

  // Gérer le changement de sélection
  const handleChangeDriverLicense = (selectedOptions) => {
    // Si rien n'est sélectionné, selectedOptions sera null
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value).join(",") // Formater en chaîne de caractères
      : "";

    setInfoPersoFormData({
      ...infoPersoFormData,
      driverLicense: selectedValues,
    });
  };

  const handleSubmitInfoPerso = (event) => {
    event.preventDefault(); // Empêche le rafraîchissement de la page
    const formattedData = {
      lastName: infoPersoFormData.lastName || "",
      firstName: infoPersoFormData.firstName || "",
      birthday: infoPersoFormData.birthday || "", // Assurez-vous que la date est au format "YYYY-MM-DD"
      available: infoPersoFormData.available || false,
      latestPosition: infoPersoFormData.latestPosition || "",
      summary: infoPersoFormData.summary || "",
      experienceLevelId: infoPersoFormData.experienceLevelId || 1,
      urlFacebook: infoPersoFormData.urlFacebook || "",
      whatsapp: infoPersoFormData.whatsapp || "",
      urlTwitter: infoPersoFormData.urlTwitter || "",
      urlLinkedIn: infoPersoFormData.urlLinkedIn || "",
      driverLicense: infoPersoFormData.driverLicense || "",
      address: {
        id: infoPersoDetails.data?.address?.id || null,
        country: infoPersoFormData.country || "",
        city: infoPersoFormData.city || "",
        street: infoPersoFormData.street || "",
        zipCode: infoPersoFormData.zipCode || 0,
      },
    };
    // console.log("Données soumises :", formattedData);
    if (selectedImageFile) {
      dispatch(uploadProfilePicture(selectedImageFile));
    }
    // Effectuez votre logique de soumission ici, par exemple en utilisant une action Redux
    dispatch(updateCandidateInfoPerso(formattedData))
      .then(() => {
        message.success("Informations mises à jour avec succès");
        window.location.reload(); // Recharge toute la page
      })
      .catch((err) => {
        console.error("Erreur de mise à jour :", err);
        message.error("Échec de la mise à jour des informations");
      });
  };

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [modal, setModal] = useState(false);
  const [currentContentType, setCurrentContentType] = useState("");
  const [selectedExperience, setSelectedExperience] = useState(null); // État pour l'expérience à modifier
  const [selectedEducation, setSelectedEducation] = useState(null); // État pour l'expérience à modifier
  const [currentSkill, setCurrentSkill] = useState(null); // État pour la compétence à modifier
  const [currentLanguage, setCurrentLanguage] = useState(null); // État pour la compétence à modifier

  const toggleModal = (contentType) => {
    setCurrentContentType(contentType);
    setModal(!modal);
  };

  const handleCloseModal = () => {
    setModal(false);
    dispatch(fetchCandidateExperiences());
    dispatch(fetchCandidateEducations());
    dispatch(fetchCandidateSkills());
    dispatch(fetchCandidateLanguages());
    setSelectedExperience(null); // Réinitialiser l'expérience sélectionnée
    setSelectedEducation(null); // Réinitialiser l'éducation sélectionnée
    setCurrentSkill(null); // Réinitialiser la compétence sélectionnée
    setCurrentLanguage(null); // Réinitialiser la compétence sélectionnée
    setCurrentContentType("");
  };
  // Fonction pour ouvrir le modal et remplir les données lors de la modification
  const handleEditExperience = (experience) => {
    setSelectedExperience(experience); // Stocke l'expérience sélectionnée pour l'édition
    toggleModal("Experience", experience); // Ouvre le modal avec les données de l'expérience
  };

  const handleEditEducation = (education) => {
    setSelectedEducation(education); // Stocke l'expérience sélectionnée pour l'édition
    toggleModal("Formation", education); // Ouvre le modal avec les données de l'expérience
  };
  const handleEditSkill = (skill) => {
    setCurrentSkill(skill); // Stocke la compétence sélectionnée pour l'édition
    toggleModal("Compétence", skill); // Ouvre le modal avec les données de la compétence
  };

  const handleEditLanguage = (language) => {
    setCurrentLanguage(language); // Stocke la compétence sélectionnée pour l'édition
    toggleModal("Langue", language); // Ouvre le modal avec les données de la compétence
  };

  // Fonction pour supprimer une expérience
  const deleteExperience = async (id) => {
    dispatch(deleteCandidateExperience(id))
      .then(() => {
        message.success("Expérience supprimée avec succès !");
        dispatch(fetchCandidateExperiences());
      })
      .catch(() => {
        message.error("Erreur lors de la suppression de l'expérience.");
      });
  };

  // Fonction pour supprimer une formation
  const deleteEducation = async (id) => {
    dispatch(deleteCandidateEducation(id))
      .then(() => {
        message.success("Formation supprimée avec succès !");
        dispatch(fetchCandidateEducations());
      })
      .catch(() => {
        message.error("Erreur lors de la suppression de l'expérience.");
      });
  };
  // Fonction pour supprimer une compétence
  const deleteSkill = async (id) => {
    dispatch(deleteCandidateSkill(id))
      .then(() => {
        message.success("Compétence supprimée avec succès!");
        dispatch(fetchCandidateSkills());
      })
      .catch(() => {
        message.error("Erreur lors de la suppression de la compétence.");
      });
  };
  // Fonction pour supprimer une langue
  const deleteLanguage = async (id) => {
    dispatch(deleteCandidateLanguage(id))
      .then(() => {
        message.success("Langue supprimée avec succès!");
        dispatch(fetchCandidateLanguages());
      })
      .catch(() => {
        message.error("Erreur lors de la suppression de la langue.");
      });
  };

  const icons = {
    Experience: <i className="uil uil-briefcase fs-22"></i>,
    Formation: <i className="uil uil-graduation-cap fs-22"></i>,
    Compétence: <i className="uil uil-star fs-22"></i>, // Exemple pour une compétence
    Langue: <i className="uil uil-globe fs-22"></i>, // Exemple pour une langue
    // Ajoute d'autres types de contenu et leurs icônes ici
  };

  const optionsSector = [
    { value: "design", label: "design" },
    { value: "marketing", label: "marketing" },
    { value: "developer", label: "developer" },
  ];

  const optionsDriverLicense = [
    { value: "A", label: "Permis A" },
    { value: "B", label: "Permis B" },
    { value: "C", label: "Permis C" },
    { value: "D", label: "Permis D" },
  ];

  // Formater les options sélectionnées pour l'affichage dans le Select
  const selectedValues = infoPersoFormData.driverLicense
    ? optionsDriverLicense.filter((option) =>
        infoPersoFormData.driverLicense.split(",").includes(option.value)
      )
    : [];

  const optionsContractTypes = [
    { value: "CDD", label: "CDD" },
    { value: "CDI", label: "CDI" },
    { value: "FREELANCE", label: "FREELANCE" },
  ];

  const optionsJobs = [
    { value: "Dev", label: "Dévéloppeur Informatique" },
    { value: "Design", label: "Designer" },
    { value: "CP", label: "Chef Projet" },
  ];

  const optionsLocalities = [
    { value: "CI", label: "Côte d'ivoire" },
    { value: "TG", label: "Togo" },
    { value: "GU", label: "Guinnée" },
  ];

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      color: isDisabled
        ? "#ccc" // Couleur du texte pour les options désactivées
        : isSelected
        ? "white" // Couleur du texte pour les options sélectionnées
        : isFocused
        ? "#50D3BB" // Couleur du texte pour les options survolées
        : "black", // Couleur du texte pour les options normales
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      borderColor: isFocused ? "#86d9da" : "#f3f4f6", // Bordure verte
      "&:hover": {
        borderColor: isFocused ? "#86d9da" : "#f3f4f6", // Garder la bordure verte au survol
      },
      boxShadow: isFocused ? "0 0 0 1px #86d9da" : "none", // Ombre verte lors de la focalisation
    }),
  };

  const renderForm = () => {
    switch (currentContentType) {
      case "Experience":
        return (
          <AddExperienceForm
            selectedExperience={selectedExperience} // Passer l'expérience sélectionnée au formulaire
            onCancel={handleCloseModal}
          />
        );
      case "Formation":
        return (
          <AddEducationForm
            selectedEducation={selectedEducation} // Passer l'education sélectionnée au formulaire
            onCancel={handleCloseModal}
          />
        );
      case "Compétence":
        return (
          <AddSkillForm
            selectedSkill={currentSkill} // Passer la compétence sélectionnée au formulaire
            onCancel={handleCloseModal}
          />
        );
      case "Langue":
        return (
          <AddLanguageForm
            selectedLanguage={currentLanguage} // Passer la langue sélectionnée au formulaire
            onCancel={handleCloseModal}
          />
        );
      default:
        return null;
    }
  };

  const formatDate = (datestring) => {
    const options = { month: "short", year: "numeric" };
    return new Date(datestring).toLocaleDateString("fr-FR", options); // Utiliser "fr-FR" pour le français
  };

  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="dossier de compétence">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                  "text-danger": activeTab === "1", // Classe pour le texte rouge
                })}
                onClick={() => {
                  tabChange("1");
                }}
                type="button"
              >
                Dossier de compétence
              </NavLink>
            </NavItem>
            <NavItem role="informations personnelles">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  tabChange("2");
                }}
                type="button"
              >
                Infos persos
              </NavLink>
            </NavItem>
            <NavItem role="ma situation">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  tabChange("3");
                }}
                type="button"
              >
                Ma situation
              </NavLink>
            </NavItem>
            <NavItem role="ma recherche">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "4",
                })}
                onClick={() => {
                  tabChange("4");
                }}
                type="button"
              >
                Mes préférences
              </NavLink>
            </NavItem>
          </Nav>
          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Modal
                  isOpen={modal}
                  toggle={handleCloseModal}
                  style={{ maxWidth: "700px" }}
                  centered
                  backdrop="static"
                >
                  <div className="modal-header">
                    <h5 className="modal-title text-danger">
                      {icons[currentContentType]} {currentContentType}
                    </h5>
                  </div>
                  <div
                    className="modal-body"
                    style={{ maxHeight: "600px", overflowY: "auto" }} // Style en ligne
                  >
                    {/* Appel à renderForm pour afficher le formulaire correspondant */}
                    {renderForm()}
                  </div>
                </Modal>

                <div className="candidate-education-details">
                  <h6 className="fs-18 fw-bold mb-0 text-danger">
                    <i className="uil uil-briefcase fs-22"></i> EXPERIENCE(S){" "}
                    <Button
                      onClick={() => toggleModal("Experience", null)}
                      shape="circle"
                      icon={<PlusOutlined />}
                    />
                  </h6>
                  <hr />
                  {loading && <Skeleton active paragraph={{ rows: 4 }} />}{" "}
                  {error && (
                    <p className="text-muted mt-2">
                      Une erreur est survenue : {error}
                    </p>
                  )}
                  {!loading &&
                    !error &&
                    allExperiences?.content?.length > 0 &&
                    allExperiences.content.map((experience) => (
                      <div key={experience.id} className="mt-4 d-flex">
                        <div className="ms-4">
                          {/* Titre en majuscule avec icônes modifier et supprimer */}
                          <div className="d-flex justify-content-between">
                            <h6 className="fs-16 mb-1 text-uppercase">
                              {experience.position}
                            </h6>
                            <div>
                              <Button
                                type="text"
                                style={{ color: "red" }}
                                icon={<EditOutlined />}
                                shape="circle"
                                onClick={() => handleEditExperience(experience)}
                              />
                              <Popconfirm
                                title="Êtes-vous sûr de vouloir supprimer cette expérience ?"
                                onConfirm={() =>
                                  deleteExperience(experience.id)
                                } // Confirme la suppression
                                okText="Oui"
                                cancelText="Non"
                              >
                                <Button
                                  type="text"
                                  style={{ color: "red" }}
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                />
                              </Popconfirm>
                            </div>
                          </div>

                          {/* Entreprise et période */}
                          <p className="mb-1 mt-2 fw-bold text-danger">
                            {experience.company} - (
                            {formatDate(experience.startDate)} -{" "}
                            {formatDate(experience.endDate)})
                          </p>

                          {/* Contexte */}
                          {experience.context && (
                            <p className="text-muted">{experience.context}</p>
                          )}

                          {/* Actions */}
                          {experience.actions && (
                            <>
                              <p className="text-danger">Missions</p>
                              <ul className="text-muted">
                                {experience.actions
                                  .split("\n")
                                  .map((action, index) => (
                                    <li key={index}>{action.trim()}</li>
                                  ))}
                              </ul>
                            </>
                          )}

                          {/* Résultats */}
                          {experience.results && (
                            <>
                              <p className="text-danger">Objectifs</p>
                              <ul className="text-muted">
                                {experience.results
                                  .split("\n")
                                  .map((result, index) => (
                                    <li key={index}>{result.trim()}</li>
                                  ))}
                              </ul>
                            </>
                          )}

                          {/* Environnement technique */}
                          {experience.technicalEnvironment && (
                            <>
                              <p className="text-danger">
                                Environnement technique
                              </p>
                              <p className="text-muted">
                                {experience.technicalEnvironment
                                  .replace(/\n/g, ", ") // Remplacer les retours à la ligne par des virgules
                                  .split(",")
                                  .map((tech, index) => tech.trim())
                                  .join(", ")}{" "}
                                {/* Affichage sur une seule ligne */}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  {/* Si aucune expérience n'est disponible */}
                  {!loading &&
                    !error &&
                    allExperiences?.content?.length === 0 && (
                      <p className="text-muted mt-2">
                        Aucune expérience disponible.
                      </p>
                    )}
                </div>

                <div className="candidate-education-details mt-4">
                  <h6 className="fs-18 fw-bold mb-0 text-info">
                    <i className="uil uil-graduation-cap fs-22"></i>
                    FORMATION(S){" "}
                    <Button
                      onClick={() => toggleModal("Formation", null)}
                      shape="circle"
                      icon={<PlusOutlined />}
                    />
                  </h6>
                  <hr />
                  {educationLoading && (
                    <Skeleton active paragraph={{ rows: 4 }} />
                  )}
                  {educationError && (
                    <p className="text-muted mt-2">
                      Une erreur est survenue : {educationError}
                    </p>
                  )}
                  {!educationLoading &&
                    !educationError &&
                    allEducations?.content?.length > 0 &&
                    // Tri des formations par date de début (date la plus récente en premier)
                    allEducations.content
                      .sort(
                        (a, b) => new Date(b.startDate) - new Date(a.startDate)
                      ) // Tri décroissant par startDate
                      .map((formation, index) => (
                        <div key={formation.id} className="mt-4 d-flex">
                          <div className="ms-4">
                            {/* Titre en majuscule avec icônes modifier et supprimer */}
                            <div className="d-flex justify-content-between">
                              <h6 className="fs-16 mb-1 text-uppercase">
                                {formation.name}
                              </h6>
                              <div>
                                <Button
                                  type="text"
                                  style={{ color: "#31ABDF" }}
                                  icon={<EditOutlined />}
                                  shape="circle"
                                  onClick={() => handleEditEducation(formation)}
                                />
                                <Popconfirm
                                  title="Êtes-vous sûr de vouloir supprimer cette formation ?"
                                  onConfirm={() =>
                                    deleteEducation(formation.id)
                                  } // Confirme la suppression
                                  okText="Oui"
                                  cancelText="Non"
                                >
                                  <Button
                                    type="text"
                                    style={{ color: "#31ABDF" }}
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                  />
                                </Popconfirm>
                              </div>
                            </div>
                            <p className="mb-2 text-info">
                              {formation.school} - (
                              {formatDate(formation.startDate)} -{" "}
                              {formatDate(formation.endDate)})
                            </p>

                            {formation.summary
                              .split("\n")
                              .map((summary, index) => (
                                <p className="text-muted" key={index}>
                                  {summary.trim()}{" "}
                                </p>
                              ))}
                          </div>
                        </div>
                      ))}
                  {!educationLoading &&
                    !educationError &&
                    allEducations?.content?.length === 0 && (
                      <p className="text-muted mt-2">
                        Aucune formation disponible.
                      </p>
                    )}
                </div>

                <div className="mt-4">
                  <h5 className="fs-18 fw-bold text-primary">
                    <i className="uil uil-file-bookmark-alt fs-22"></i>{" "}
                    COMPETENCE(S){" "}
                    <Button
                      onClick={() => toggleModal("Compétence", null)}
                      shape="circle"
                      icon={<PlusOutlined />}
                    />
                  </h5>
                  <hr />
                </div>

                <div className="mt-0">
                  {skillLoading && <Skeleton active paragraph={{ rows: 1 }} />}
                  {skillError && (
                    <p className="text-muted mt-2">
                      Une erreur est survenue : {skillError}
                    </p>
                  )}
                  {!skillError && allSkills?.content?.length > 0 && (
                    <table className="table table-inline mb-0">
                      <tbody>
                        {allSkills.content.map((skill) => (
                          <tr key={skill.id}>
                            <td>{skill.fieldOfCompetence}</td>
                            <td>
                              {skill.competence}&nbsp;&nbsp;
                              <Rate
                                disabled
                                value={skill.experienceLevel.id}
                                count={4}
                                style={{ color: "#50D3BB" }}
                              />
                            </td>
                            <td className="text-end">
                              <Button
                                type="text"
                                style={{ color: "#50D3BB" }}
                                icon={<EditOutlined />}
                                shape="circle"
                                onClick={() => handleEditSkill(skill)}
                              />
                              <Popconfirm
                                title="Êtes-vous sûr de vouloir supprimer cette compétence ?"
                                onConfirm={() => deleteSkill(skill.id)}
                                okText="Oui"
                                cancelText="Non"
                              >
                                <Button
                                  type="text"
                                  style={{ color: "#50D3BB" }}
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                />
                              </Popconfirm>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {!skillLoading &&
                    !skillError &&
                    allSkills?.content?.length === 0 && (
                      <p className="text-muted mt-2">
                        Aucune compétence disponible.
                      </p>
                    )}
                </div>

                <div className="mt-4">
                  <h5 className="fs-18 fw-bold  text-warning">
                    <i className="uil uil-comments-alt fs-22"></i> LANGUE(S){" "}
                    <Button
                      onClick={() => toggleModal("Langue", null)}
                      shape="circle"
                      icon={<PlusOutlined />}
                    />
                  </h5>
                  <hr />
                </div>
                <div className="mt-0 d-flex flex-wrap align-items-start gap-1">
                  {languageLoading && (
                    <Skeleton active paragraph={{ rows: 1 }} />
                  )}{" "}
                  {languageError && (
                    <p className="text-muted mt-2">
                      Une erreur est survenue : {skillError}
                    </p>
                  )}
                  {!languageError && allLanguages?.content?.length > 0 && (
                    <table className="table table-inline mb-0">
                      <tbody>
                        {allLanguages.content.map((language) => (
                          <tr key={language.id}>
                            <td>{language?.language?.name}</td>
                            <td>
                              <Rate
                                disabled
                                value={languageLevelMap[language.level]} // Utilisez le mapping ici
                                count={3}
                                style={{ color: "#F9BB4A" }}
                              />
                            </td>
                            <td className="text-end">
                              <Button
                                type="text"
                                style={{ color: "#F9BB4A" }}
                                icon={<EditOutlined />}
                                shape="circle"
                                onClick={() => handleEditLanguage(language)}
                              />
                              <Popconfirm
                                title="Êtes-vous sûr de vouloir supprimer cette langue ?"
                                onConfirm={() => deleteLanguage(language.id)}
                                okText="Oui"
                                cancelText="Non"
                              >
                                <Button
                                  type="text"
                                  style={{ color: "#F9BB4A" }}
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                />
                              </Popconfirm>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {!languageLoading &&
                    !languageError &&
                    allLanguages?.content?.length === 0 && (
                      <p className="text-muted mt-2">
                        Aucune langue disponible.
                      </p>
                    )}
                </div>
                <div className="mt-4 text-end">
                  <Link
                    to="#"
                    className="btn btn-danger"
                    onClick={() => window.open("/monCV", "_blank")}
                  >
                    <i class="uil uil-eye"></i> Visualiser
                  </Link>
                </div>
              </TabPane>
              <TabPane tabId="2">
                {infoPersoLoading ? (
                  <Skeleton active paragraph={{ rows: 4 }} />
                ) : (
                  <Form onSubmit={handleSubmitInfoPerso}>
                    <div>
                      <h5 className="fs-17 fw-semibold mb-3 mb-0">
                        Mon Compte
                      </h5>
                      <div className="text-center">
                        <div className="mb-4 profile-user">
                          <img
                            src={
                              profileImage ||
                              infoPersoFormData.urlPicture ||
                              "https://res.cloudinary.com/dryskp7hv/image/upload/v1718890903/189632_pu5gij.png"
                            }
                            className="rounded-circle img-thumbnail profile-img"
                            id="profile-img"
                            alt=""
                          />
                          <div className="p-0 rounded-circle profile-photo-edit">
                            <Input
                              id="profile-img-file-input"
                              type="file"
                              className="profile-img-file-input"
                              onChange={handleImageChange}
                            />
                            <Label
                              htmlFor="profile-img-file-input"
                              className="profile-photo-edit avatar-xs"
                            >
                              <i className="uil uil-edit"></i>
                            </Label>
                          </div>
                        </div>
                      </div>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">
                              Nom
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="firstName"
                              value={infoPersoFormData.firstName}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="lastName" className="form-label">
                              Prénom(s)
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="lastName"
                              value={infoPersoFormData.lastName}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="whatsapp" className="form-label">
                              Téléphone
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="whatsapp"
                              placeholder="(+225) 123 456 7890"
                              value={infoPersoFormData.whatsapp}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="birthday" className="form-label">
                              Date de naissance
                            </Label>
                            <Input
                              type="date"
                              name="birthday"
                              id="birthday"
                              value={infoPersoFormData.birthday}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="street" className="form-label">
                              Rue
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="street"
                              value={infoPersoFormData.street}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="city" className="form-label">
                              Ville
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="city"
                              value={infoPersoFormData.city}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label htmlFor="country" className="form-label">
                              Pays
                            </label>
                            <select
                              className="form-select"
                              data-trigger
                              name="country"
                              id="country"
                              aria-label="Default select example"
                              value={infoPersoFormData.country}
                              onChange={handleChangeInfoPerso}
                            >
                              <option value=""></option>
                              <option value="Cote D'ivoire">
                                Côte d'ivoire
                              </option>
                              <option value="Togo">Togo</option>
                              <option value="Guinnée">Guinnée</option>
                              <option value="Allemagne">Allemagne</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="zipCode" className="form-label">
                              Code Postale
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="zipCode"
                              value={infoPersoFormData.zipCode}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <label
                              htmlFor="driverLicense"
                              className="form-label"
                            >
                              Permis de conduite
                            </label>
                            <div className="selecte-tag position-relative">
                              <Select
                                id="driverLicense"
                                name="driverLicense"
                                placeholder="Sélectionner le type de permis de conduire"
                                options={optionsDriverLicense}
                                isMulti
                                className="selecte-tag position-relative"
                                classNamePrefix="select"
                                styles={colourStyles}
                                value={selectedValues} // Utiliser les options correspondantes aux valeurs sélectionnées
                                onChange={handleChangeDriverLicense}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-4">
                      <h5 className="fs-17 fw-semibold mb-3">Profile</h5>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Présentez-vous
                            </Label>
                            <textarea
                              className="form-control"
                              rows="5"
                              id="summary"
                              value={infoPersoFormData.summary}
                              onChange={handleChangeInfoPerso}
                            ></textarea>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="attachmentsCv"
                              className="form-label"
                            >
                              Mon CV
                            </Label>
                            <Input
                              className="form-control"
                              type="file"
                              id="attachmentsCv"
                              value={infoPersoFormData.attachmentsCv}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="attachmentsCertificate"
                              className="form-label"
                            >
                              Mes Certifications
                            </Label>
                            <Input
                              className="form-control"
                              type="file"
                              id="attachmentsCertificate"
                              value={infoPersoFormData.attachmentsCertificate}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-4">
                      <h5 className="fs-17 fw-semibold mb-3">
                        Réseaux Sociaux
                      </h5>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="urlFacebook" className="form-label">
                              Facebook
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="urlFacebook"
                              value={infoPersoFormData.urlFacebook || ""}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="urlTwitter" className="form-label">
                              Twitter
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="urlTwitter"
                              value={infoPersoFormData.urlTwitter || ""}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="urlLinkedIn" className="form-label">
                              Linkedin
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="urlLinkedIn"
                              value={infoPersoFormData.urlLinkedIn || ""}
                              onChange={handleChangeInfoPerso}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-4 text-end">
                      <ReactstrapButton
                        type="submit"
                        color="primary"
                        className="me-2"
                      >
                        Mettre à jour
                      </ReactstrapButton>
                    </div>
                  </Form>
                )}
              </TabPane>
              <TabPane tabId="3">
                <Form action="#">
                  <div>
                    <h5 className="fs-17 fw-semibold mb-3 mb-0">
                      Ma situation actuelle
                    </h5>

                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Poste actuel ou dernier poste occupé
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id="firstName"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <label
                            htmlFor="CurrentSituation"
                            className="form-label"
                          >
                            Situation actuelle
                          </label>
                          <select
                            className="form-select"
                            data-trigger
                            name="CurrentSituation"
                            id="CurrentSituation"
                            aria-label="Default select example"
                          >
                            <option value=""></option>
                            <option value="IN_OFFICE">En poste</option>
                            <option value="UNEMPLOYED">Sans emploi</option>
                          </select>
                        </div>
                      </Col>
                      <Col lg={8}>
                        <div className="mb-3">
                          <label htmlFor="Availability" className="form-label">
                            Disponobilité
                          </label>
                          <select
                            className="form-select"
                            data-trigger
                            name="Availability"
                            id="Availability"
                            aria-label="Default select example"
                          >
                            <option value=""></option>
                            <option value="IMMEDIATE">Immédiate</option>
                            <option value="TO_BE_AGREED">A convenir</option>
                          </select>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="experience" className="form-label">
                            Niveau d'expérience
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="experience"
                          />
                        </div>
                      </Col>
                      <Col lg={8}>
                        <div className="mb-3">
                          <label htmlFor="sector" className="form-label">
                            Secteur d'activité
                          </label>
                          <div className="selecte-tag position-relative">
                            <Select
                              defaultValue={[]}
                              isMulti
                              color="primary"
                              name="sector"
                              placeholder=""
                              options={optionsSector}
                              className="selecte-tag position-relative"
                              classNamePrefix="select"
                              styles={colourStyles}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="qualification" className="form-label">
                            Niveau de qualification
                          </Label>
                          <Input
                            type="text"
                            name="qualification"
                            id="qualification"
                            required
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <div className="area-range slidecontainer">
                            <div className="form-label mb-4">
                              Salaire (Annuel brute): {value} kE
                              <span
                                className="example-val mt-2"
                                id="slider1-span"
                              ></span>
                            </div>
                            <input
                              type="range"
                              min="1"
                              max="100"
                              value={value}
                              onChange={({ target: { value } }) =>
                                setValue(value)
                              }
                              className={`slider ${
                                value > 50 ? "slider-50" : "slider-0"
                              }`}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="form-check mb-2 mt-3">
                          <label
                            className="form-check-label ms-2"
                            htmlFor="disabledWorker"
                          >
                            Reconnu travailleur handicapé
                          </label>
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="disabledWorker"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-4 text-end">
                    <Link to="#" className="btn btn-primary">
                      Mettre à jour
                    </Link>
                  </div>
                </Form>
              </TabPane>
              <TabPane tabId="4">
                <Form action="#">
                  <div>
                    <p className="fs-14 text-muted fw-semibold mb-4">
                      Ces infos nous permettent de vous envoyer de meilleurs
                      recommandations !
                    </p>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <label htmlFor="contractTypes" className="form-label">
                            Type(s) de contract
                          </label>
                          <div className="selecte-tag position-relative">
                            <Select
                              defaultValue={[]}
                              isMulti
                              color="primary"
                              name="contractTypes"
                              placeholder=""
                              options={optionsContractTypes}
                              className="selecte-tag position-relative"
                              classNamePrefix="select"
                              styles={colourStyles}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <label htmlFor="jobs" className="form-label">
                            Metier recherché(s) (3 choix max)
                          </label>
                          <div className="selecte-tag position-relative">
                            <Select
                              defaultValue={[]}
                              isMulti
                              color="primary"
                              name="jobs"
                              placeholder=""
                              options={optionsJobs}
                              className="selecte-tag position-relative"
                              classNamePrefix="select"
                              styles={colourStyles}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <label htmlFor="localities" className="form-label">
                            Localité(s) recherché(s) (3 choix max)
                          </label>
                          <div className="selecte-tag position-relative">
                            <Select
                              defaultValue={[]}
                              isMulti
                              color="primary"
                              name="localities"
                              placeholder=""
                              options={optionsLocalities}
                              className="selecte-tag position-relative"
                              classNamePrefix="select"
                              styles={colourStyles}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-4 text-end">
                    <Link to="#" className="btn btn-primary">
                      Mettre à jour
                    </Link>
                  </div>
                </Form>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
