import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrainingDetails } from "../../../redux/training/action";
import { useParams, useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import * as errorAnimationData from "../../../../src/assets/error.json";
import TextWidget from "../BlogGrid/TextWidget";
import Archives from "../BlogGrid/Archives";
import Tags from "../BlogGrid/Tags";
import BlogSwiper from "../BlogDetails/BlogSwiper";
import BlogColumn from "../BlogDetails/BlogColumn";
import JobDetailsSkeleton from "../../Jobs/JobDetails/JobDetailsSkeleton";
import Section from "../BlogDetails/Section";

const BlogDetails = () => {
  document.title = "Formation Details | RecruTech";

  const { slug } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isTrainingAcademy = queryParams.get("trainingAcademy") === "true";

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { selectedDetails, error } = useSelector(
    (state) => state.trainingReducer
  );

  useEffect(() => {
    dispatch(fetchTrainingDetails(slug, isTrainingAcademy));
    window.scrollTo(0, 0);
  }, [dispatch, slug, isTrainingAcademy]);

  useEffect(() => {
    if (selectedDetails || error) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [selectedDetails, error]);

  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Section selectedDetails={selectedDetails} />
      <section className="section">
        <Container>
          <Row>
            {loading ? (
              <Col lg={12}>
                <div className="mt-5">
                  <JobDetailsSkeleton />
                </div>
              </Col>
            ) : error ? (
              <div className="text-center mt-5">
                <Lottie options={errorOptions} height={300} width={300} />
                <p className="text-danger mt-3">
                  Une erreur s'est produite : {error}
                </p>
              </div>
            ) : (
              <>
                <Col lg={8}>
                  <div className="blog-post">
                    <BlogSwiper selectedDetails={selectedDetails} />
                    <BlogColumn selectedDetails={selectedDetails} />
                    <Archives selectedDetails={selectedDetails} />
                    <TextWidget selectedDetails={selectedDetails} />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="sidebar ms-lg-4 ps-lg-4 mt-5 mt-lg-0">
                    <Tags selectedDetails={selectedDetails} />
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </section>
      {/* {isTrainingAcademy && (
        <div className="alert alert-info mt-4">
          Cette formation est associée à une académie.
        </div>
      )} */}
    </React.Fragment>
  );
};

export default BlogDetails;
