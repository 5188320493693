import api from "../Api";

const fetchCompanies = async () => {
  return await api.get("api/v1/public/candidates/companies/all");
};

const fetchCompaniesPaged = async (page, size = 10) => {
  return await api.get(
    `api/v1/public/candidates/companies/paged?page=${page}&size=${size}`
  );
};

const CompanyService = {
  fetchCompanies,
  fetchCompaniesPaged,
};

export default CompanyService;
