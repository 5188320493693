import React from "react";
import { Container, Row, Col } from "reactstrap";

const PrivacyAndPolicyPage = () => {
  const privacyAndPolicyPage = [
    {
      id: 1,
      policyTitle: "Introduction",
      policyRules: [
        {
          id: 1,
          policyInnerRule:
            "Bienvenue sur notre site de recherche d'emploi et de formation en ligne. Nous accordons une grande importance à la confidentialité de vos informations personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos données personnelles lorsque vous utilisez notre site.",
        },
      ],
    },
    {
      id: 2,
      policyTitle: "Informations que nous collectons",
      policyRules: [
        {
          id: 1,
          policyInnerRule:
            "Nous pouvons collecter les informations suivantes lorsque vous utilisez notre site :",
        },
        {
          id: 2,
          policyInnerRule:
            "• Nom, prénom, adresse e-mail, numéro de téléphone.",
        },
        {
          id: 3,
          policyInnerRule:
            "• Informations sur votre CV, expériences et compétences.",
        },
        {
          id: 4,
          policyInnerRule:
            "• Préférences de recherche d'emploi et historique d'utilisation.",
        },
        {
          id: 5,
          policyInnerRule:
            "• Données techniques comme l'adresse IP et les cookies.",
        },
      ],
    },
    {
      id: 3,
      policyTitle: "Utilisation de vos informations",
      policyRules: [
        {
          id: 1,
          policyInnerRule: "Nous utilisons vos données pour :",
        },
        {
          id: 2,
          policyInnerRule:
            "• Vous connecter avec des employeurs et recruteurs.",
        },
        {
          id: 3,
          policyInnerRule:
            "• Personnaliser votre expérience utilisateur et vous proposer des offres adaptées.",
        },
        {
          id: 4,
          policyInnerRule: "• Améliorer nos services et notre plateforme.",
        },
        {
          id: 5,
          policyInnerRule:
            "• Assurer la sécurité et prévenir les activités frauduleuses.",
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            {privacyAndPolicyPage.map((section) => (
              <Col lg={12} key={section.id}>
                <h5 className="mb-4">{section.policyTitle}</h5>
                <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                  {section.policyRules.map((rule) => (
                    <li key={rule.id}>{rule.policyInnerRule}</li>
                  ))}
                </ul>
              </Col>
            ))}
            {/* <div className="text-end">
              <button onClick={handlePrint} className="btn btn-primary">
                <i className="uil uil-print"></i> Imprimer
              </button>
            </div> */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PrivacyAndPolicyPage;
