import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button as ReactstrapButton,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addCandidateEducation,
  fetchCandidateEducationById,
  updateCandidateEducation,
  resetSelectedDetails, // Importer l'action de réinitialisation
} from "../../../../redux/profile/action";
import { toast, ToastContainer } from "react-toastify";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

const TrainingForm = ({ selectedEducation, onCancel }) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const [education, setEducation] = useState({
    name: "",
    startDate: "",
    endDate: "",
    school: "",
    content: "",
    summary: "",
  });

  const dispatch = useDispatch();
  const { selectedDetails } = useSelector((state) => state.profileReducer);

  // Charger les données si selectedEducation est défini
  useEffect(() => {
    const loadEducation = async () => {
      if (selectedEducation) {
        dispatch(fetchCandidateEducationById(selectedEducation.id));
      }
    };
    loadEducation();
  }, [dispatch, selectedEducation]);

  useEffect(() => {
    if (selectedDetails) {
      setEducation(selectedDetails);
    }
  }, [selectedDetails]);

  const handleChange = (e) => {
    setEducation({ ...education, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedEducation?.id) {
      dispatch(updateCandidateEducation(education))
        .then(() => {
          resetForm();
          onCancel();
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de la modification !");
        });
    } else {
      dispatch(addCandidateEducation(education))
        .then(() => {
          resetForm();
          onCancel();
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de l'ajout !");
        });
    }
  };

  // Fonction pour réinitialiser le formulaire
  const resetForm = () => {
    setEducation({
      name: "",
      startDate: "",
      endDate: "",
      school: "",
      content: "",
      summary: "",
    });
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="name">Formation*</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={education.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="school">Etablissement*</Label>
                <Input
                  type="text"
                  name="school"
                  id="school"
                  value={education.school}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="summary">Description de la formation*</Label>
            <textarea
              className="form-control"
              id="summary"
              name="summary"
              value={education.summary}
              onChange={handleChange}
              rows="5"
            ></textarea>
          </FormGroup>

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="startDate">Date de début*</Label>
                <Input
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={education.startDate}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="endDate">Date de fin*</Label>
                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  value={education.endDate}
                  onChange={handleChange}
                  disabled={education.isCurrent}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label>
              <button
                className="btn btn-link text-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMoreInfo(!showMoreInfo);
                }}
              >
                {showMoreInfo ? (
                  <>
                    <DownOutlined /> Ajouter plus de détails
                  </>
                ) : (
                  <>
                    <RightOutlined /> Ajouter plus de détails
                  </>
                )}
              </button>
            </Label>
          </FormGroup>

          {showMoreInfo && (
            <>
              <hr />
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="content">
                      Modules, Options, Contenu des cours
                    </Label>
                    <textarea
                      className="form-control"
                      id="content"
                      name="content"
                      value={education.content}
                      onChange={handleChange}
                      rows="2"
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          <div className="text-end">
            <ReactstrapButton color="danger" type="submit">
              {selectedEducation?.id ? "Modifier" : "Ajouter"} l'expérience
            </ReactstrapButton>
            <ReactstrapButton
              color="primary"
              onClick={() => {
                resetForm(); // Appeler la fonction de réinitialisation
                dispatch(resetSelectedDetails()); // Réinitialiser selectedDetails
                onCancel(); // Annuler l'action
              }}
              className="ms-2"
            >
              Annuler
            </ReactstrapButton>
          </div>
        </Form>
        <ToastContainer />
      </CardBody>
    </Card>
  );
};

export default TrainingForm;
