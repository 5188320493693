import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchCandidateInfoPerso } from "../../../redux/profile/action";

const LeftSideContent = () => {
  const dispatch = useDispatch();
  const { infoPersoDetails, infoPersoLoading, infoPersoError } = useSelector(
    (state) => state.profileReducer
  );

  useEffect(() => {
    dispatch(fetchCandidateInfoPerso());
    window.scrollTo(0, 0);
  }, [dispatch]);

  const getSafeData = (key) =>
    infoPersoDetails?.data ? infoPersoDetails.data[key] : null;

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card className="profile-sidebar me-lg-4">
          <CardBody className="p-4">
            {infoPersoLoading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              <>
                {infoPersoError ? (
                  <div className="text-center">
                    <p className="text-danger">
                      Erreur de chargement des données.
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="text-center pb-4 border-bottom">
                      <img
                        src={
                          getSafeData("user")?.imageUrl ||
                          "https://res.cloudinary.com/dryskp7hv/image/upload/v1718890903/189632_pu5gij.png"
                        }
                        alt="Profil"
                        className="avatar-lg img-thumbnail rounded-circle mb-3"
                      />
                      <h6 className="mb-3">{getSafeData("user")?.login}</h6>
                      <ul className="candidate-detail-social-menu list-inline mb-0">
                        <li className="list-inline-item">
                          <Link
                            to={getSafeData("urlFacebook") || "#"}
                            className="social-link rounded-3 btn-soft-primary"
                          >
                            <i className="uil uil-facebook-f"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to={getSafeData("urlTwitter") || "#"}
                            className="social-link rounded-3 btn-soft-info"
                          >
                            <i className="uil uil-twitter-alt"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to={getSafeData("urlLinkedIn") || "#"}
                            className="social-link rounded-3 btn-soft-danger"
                          >
                            <i className="uil uil-linkedin-alt"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4 border-bottom pb-4">
                      <h5 className="fs-17 fw-bold mb-3">Documents</h5>
                      <ul className="profile-document list-unstyled mb-0">
                        <li>
                          <div className="profile-document-list d-flex align-items-center mt-4 ">
                            <div className="icon flex-shrink-0">
                              <i className="uil uil-file"></i>
                            </div>
                            <div className="ms-3">
                              <h6 className="fs-16 mb-0">CV.pdf</h6>
                              <p className="text-muted mb-0">1.25 MB</p>
                            </div>
                            <div className="ms-auto">
                              <Link
                                to="#"
                                download
                                className="fs-20 text-muted"
                              >
                                <i className="uil uil-import"></i>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="profile-document-list d-flex align-items-center mt-4 ">
                            <div className="icon flex-shrink-0">
                              <i className="uil uil-file"></i>
                            </div>
                            <div className="ms-3">
                              <h6 className="fs-16 mb-0">
                                Certification(s).pdf
                              </h6>
                              <p className="text-muted mb-0">1.25 MB</p>
                            </div>
                            <div className="ms-auto">
                              <Link
                                to="#"
                                download="dark-logo"
                                className="fs-20 text-muted"
                              >
                                <i className="uil uil-import"></i>
                              </Link>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="mt-4">
                      <h5 className="fs-17 fw-bold mb-3">Contacts</h5>
                      <div className="profile-contact">
                        <ul className="list-unstyled mb-0">
                          <li>
                            <div className="d-flex">
                              <label>Télephone</label>
                              <div>
                                <p className="text-muted text-break mb-0">
                                  {infoPersoDetails?.data?.whatsapp || "N/A"}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <label>Adresse</label>
                              <div>
                                <p className="text-muted mb-0">
                                  {getSafeData("address")
                                    ? `${getSafeData("address").city || ""}${
                                        getSafeData("address").city ? ", " : ""
                                      }${getSafeData("address").country || ""}`
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LeftSideContent;
