import * as JobActionTypes from "../types";

const initialState = {
  job: {
    content: [],
    totalPages: undefined,
    currentPage: 0,
    totalElements: undefined,
  },
  loading: false,
  error: undefined,
  recentJobs: [],
  selectedDetails: undefined,
};

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case JobActionTypes.FETCH_JOBS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case JobActionTypes.FETCH_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        job: action.payload.data,
        error: null,
      };
    case JobActionTypes.FETCH_JOBS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case JobActionTypes.FETCH_RECENT_JOBS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case JobActionTypes.FETCH_RECENT_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        recentJobs: action.payload.data,
        error: null,
      };
    case JobActionTypes.FETCH_RECENT_JOBS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case JobActionTypes.SELECT_JOB_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        selectedDetails: undefined, // Reset selectedDetails on new request
      };
    case JobActionTypes.SELECT_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedDetails: action.payload.data,
        error: null,
      };
    case JobActionTypes.SELECT_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default jobReducer;
