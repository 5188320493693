import React, { useEffect, useRef, useState } from "react";
import { Col, Card, CardBody, Row, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTrainingsPaged } from "../../../redux/training/action";
import { Link } from "react-router-dom";
import { fr } from "date-fns/locale"; // Importez la locale française
import { format } from "date-fns"; // Importer la fonction de formatage

//Lightbox
import "react-image-lightbox/style.css";
import Masonry from "react-masonry-component";

import Lottie from "react-lottie";
// import * as loadingAnimationData from "../../../../src/assets/loading.json";
import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur
import * as emptyAnimationData from "../../../../src/assets/emptyData.json"; // Animation pour données vides
import JobCardItemSkeleton from "../../Jobs/JobGrid/JobCardItemSkeleton";

const RightSideContent = ({ selectedDetails }) => {
  const [loading, setLoading] = useState(true);
  const firstCardRef = useRef(null);
  const dispatch = useDispatch();
  const { allTrainings, error } = useSelector((state) => state.trainingReducer);
  useEffect(() => {
    // Initialiser avec la première page
    dispatch(fetchAllTrainingsPaged(selectedDetails)); // Passer 1 pour la première page
    window.scrollTo(0, 0);
  }, [dispatch, selectedDetails]);
  // Gérer l'état de chargement en fonction des données ou des erreurs
  useEffect(() => {
    if (allTrainings) {
      // Simuler un délai de 3000ms (3 secondes)
      setTimeout(() => {
        setLoading(false);
      }, 1000); // 3000 millisecondes = 3 secondes
    }
  }, [allTrainings]);

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // Paramètres pour l'animation Lottie de données vides
  const emptyOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="candidate-details ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4 candidate-personal-detail">
            <div>
              <h6 className="fs-17 fw-semibold mb-3">À propos de l'académie</h6>
              <div
                className="text-muted mb-2"
                dangerouslySetInnerHTML={{ __html: selectedDetails?.summary }}
              ></div>
            </div>
            <div className="candidate-portfolio mt-4 pt-3">
              <h6 className="fs-17 fw-bold mb-5">Ma Formation</h6>
              <Row>
                <Container>
                  {loading ? (
                    <div className="text-center mt-5">
                      <Row className="align-items-center mb-5">
                        <Col md={12}>
                          <JobCardItemSkeleton />
                        </Col>
                      </Row>{" "}
                    </div>
                  ) : error ? (
                    <div className="text-center mt-5">
                      <Lottie options={errorOptions} height={100} width={100} />
                      <p className="text-danger mt-5 mb-5">
                        Une erreur s'est produite : {error}
                      </p>
                    </div>
                  ) : allTrainings?.content?.length === 0 ? ( // Utilisation du "?" pour éviter l'erreur
                    <div className="text-center mt-5">
                      <Lottie options={emptyOptions} height={100} width={100} />
                      <p className="text-muted mt-3">
                        Aucune formation trouvée. Veuillez revenir plus tard.
                      </p>
                    </div>
                  ) : (
                    <>
                      <Masonry className="row mb-3">
                        {allTrainings.content.map((training) => (
                          <Col
                            lg={12}
                            md={6}
                            ref={firstCardRef}
                            className="mb-4"
                          >
                            <Card className="blog-masonry-box shadow overflow-hidden border-0 p-2">
                              <div className="card-header">
                                <Link
                                  to={`/formation-details/${training.slug}?trainingAcademy=true`}
                                  className="img-link"
                                >
                                  <img
                                    src={
                                      training.coverPhoto ||
                                      "https://res.cloudinary.com/dryskp7hv/image/upload/v1731180689/coding-workshop-concept-illustration_114360-8112_aylxuj.avif"
                                    }
                                    alt={training.title}
                                    className="img-fluid"
                                  />
                                </Link>
                              </div>
                              <CardBody className="p-4">
                                <Link
                                  to={`/formation-details/${training.slug}?trainingAcademy=true`}
                                  className="primary-link"
                                >
                                  <h6>{training.title}</h6>
                                </Link>
                                <div className="d-flex align-items-center mt-4">
                                  <div className="flex-shrink-0">
                                    <img
                                      src={
                                        training.authorImage ||
                                        "https://res.cloudinary.com/dryskp7hv/image/upload/v1718890903/189632_pu5gij.png"
                                      }
                                      alt={training.authorName}
                                      className="avatar-xs rounded-circle"
                                      style={{
                                        border: "3px solid #28a745", // Couleur verte avec une largeur de 3px
                                        padding: "2px", // Espacement entre l'image et la bordure
                                        borderRadius: "50%", // Assurez-vous que la bordure suit la forme circulaire de l'image
                                      }}
                                    />
                                  </div>
                                  <div className="ms-3">
                                    <h6 className="fs-14 mb-1">
                                      {training.authorName}
                                    </h6>
                                    <p className="text-muted fs-14 mb-0">
                                      Formateur
                                    </p>
                                  </div>
                                </div>
                                <p className="text-muted fs-14 mt-2 mb-2">
                                  <b>Débute le</b>{" "}
                                  <i className="mdi mdi-circle-medium"></i>{" "}
                                  {format(
                                    new Date(training.startDate),
                                    "dd MMMM yyyy",
                                    { locale: fr }
                                  )}
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Masonry>
                    </>
                  )}
                </Container>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
