import ProfileService from "../../../service/profile";
import * as ProfileActionTypes from "../types";

export const resetSelectedDetails = () => ({
  type: "RESET_SELECTED_DETAILS",
});

export const fetchContractTypes = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_CONTRACT_TYPES_REQUEST });
    try {
      const response = await ProfileService.fetchContractTypes();
      // console.log('rrr',response);

      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_CONTRACT_TYPES_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_CONTRACT_TYPES_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchLevelTypes = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_LEVEL_TYPES_REQUEST });
    try {
      const response = await ProfileService.fetchLevelTypes();
      // console.log('rrr',response);

      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_LEVEL_TYPES_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_LEVEL_TYPES_FAILURE,
        payload: error.message,
      });
    }
  };
};

// infoPerso

export const fetchCandidateInfoPerso = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateInfoPerso();
      // console.log("rrr", response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_FAILURE,
        payload: error.message,
      });
    }
  };
};
export const updateCandidateInfoPerso = (infoPersoData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_REQUEST });
    try {
      const response = await ProfileService.updateCandidateInfoPerso(
        infoPersoData
      );
      // console.log('rrr',response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_FAILURE,
        payload: error.message,
      });
    }
  };
};
// language types actions

export const fetchLanguageTypes = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_LANGUAGE_TYPES_REQUEST });
    try {
      const response = await ProfileService.fetchLanguageTypes();
      // console.log('rrr',response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_LANGUAGE_TYPES_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_LANGUAGE_TYPES_FAILURE,
        payload: error.message,
      });
    }
  };
};

//Experience actions

export const fetchCandidateExperiences = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_CANDIDATE_EXPERIENCE_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateExperiences();
      // console.log('rrr',response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_CANDIDATE_EXPERIENCE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_CANDIDATE_EXPERIENCE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchCandidateExperienceById = (experienceId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.SELECT_PROFILE_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateExperienceById(
        experienceId
      );
      // console.log('rrrs',response);
      if (response && response.data && response.statusCode === 200) {
        dispatch({
          type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const addCandidateExperience = (experienceData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.ADD_CANDIDATE_EXPERIENCE_REQUEST });
    try {
      const response = await ProfileService.addCandidateExperience(
        experienceData
      );
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_EXPERIENCE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_EXPERIENCE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateCandidateExperience = (experienceData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.UPDATE_CANDIDATE_EXPERIENCE_REQUEST });
    try {
      const response = await ProfileService.updateCandidateExperience(
        experienceData
      );
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_EXPERIENCE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_EXPERIENCE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const deleteCandidateExperience = (experienceId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.DELETE_CANDIDATE_EXPERIENCE_REQUEST });
    try {
      const response = await ProfileService.deleteCandidateExperience(
        experienceId
      );
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_EXPERIENCE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_EXPERIENCE_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Formation

export const fetchCandidateEducations = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_CANDIDATE_EDUCATION_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateEducations();
      // console.log('rrr',response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_CANDIDATE_EDUCATION_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_CANDIDATE_EDUCATION_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchCandidateEducationById = (educationId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.SELECT_PROFILE_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateEducationById(
        educationId
      );
      // console.log('rrrs',response);
      if (response && response.data && response.statusCode === 200) {
        dispatch({
          type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const addCandidateEducation = (educationData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.ADD_CANDIDATE_EDUCATION_REQUEST });
    try {
      const response = await ProfileService.addCandidateEducation(
        educationData
      );
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_EDUCATION_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_EDUCATION_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateCandidateEducation = (educationData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.UPDATE_CANDIDATE_EDUCATION_REQUEST });
    try {
      const response = await ProfileService.updateCandidateEducation(
        educationData
      );
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_EDUCATION_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_EDUCATION_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const deleteCandidateEducation = (educationId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.DELETE_CANDIDATE_EDUCATION_REQUEST });
    try {
      const response = await ProfileService.deleteCandidateEducation(
        educationId
      );
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_EDUCATION_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_EDUCATION_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Compétences

export const fetchCandidateSkills = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_CANDIDATE_SKILLS_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateSkills();
      // console.log('rrr',response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_CANDIDATE_SKILLS_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_CANDIDATE_SKILLS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchCandidateSkillById = (skillId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.SELECT_PROFILE_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateSkillById(skillId);
      // console.log('rrrs',response);
      if (response && response.data && response.statusCode === 200) {
        dispatch({
          type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const addCandidateSkill = (skillData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.ADD_CANDIDATE_SKILL_REQUEST });
    try {
      const response = await ProfileService.addCandidateSkill(skillData);
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_SKILL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_SKILL_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateCandidateSkill = (skillData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.UPDATE_CANDIDATE_SKILL_REQUEST });
    try {
      const response = await ProfileService.updateCandidateSkill(skillData);
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_SKILL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_SKILL_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const deleteCandidateSkill = (skillId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.DELETE_CANDIDATE_SKILL_REQUEST });
    try {
      const response = await ProfileService.deleteCandidateSkill(skillId);
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_SKILL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_SKILL_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Languages

export const fetchCandidateLanguages = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_CANDIDATE_LANGUAGES_REQUEST });
    try {
      const response = await ProfileService.fetchCandidatelanguages();
      // console.log('rrr',response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_CANDIDATE_LANGUAGES_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_CANDIDATE_LANGUAGES_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchCandidateLanguageById = (languageId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.SELECT_PROFILE_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateLanguageById(
        languageId
      );
      // console.log('rrrs',response);
      if (response && response.data && response.statusCode === 200) {
        dispatch({
          type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const addCandidateLanguage = (languageData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.ADD_CANDIDATE_LANGUAGE_REQUEST });
    try {
      const response = await ProfileService.addCandidateLanguage(languageData);
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_LANGUAGE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_LANGUAGE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateCandidateLanguage = (languageData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.UPDATE_CANDIDATE_LANGUAGE_REQUEST });
    try {
      const response = await ProfileService.updateCandidateLanguage(
        languageData
      );
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_LANGUAGE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_LANGUAGE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const deleteCandidateLanguage = (languageId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.DELETE_CANDIDATE_LANGUAGE_REQUEST });
    try {
      const response = await ProfileService.deleteCandidateLanguage(languageId);
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_LANGUAGE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_LANGUAGE_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Hobbies

export const fetchCandidateHobbies = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_CANDIDATE_HOBBIES_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateHobbies();
      // console.log('rrr',response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_CANDIDATE_HOBBIES_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_CANDIDATE_HOBBIES_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchCandidateHobbyById = (hobbyId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.SELECT_PROFILE_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateHobbyById(hobbyId);
      // console.log('rrrs',response);
      if (response && response.data && response.statusCode === 200) {
        dispatch({
          type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const addCandidateHobby = (hobbyData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.ADD_CANDIDATE_HOBBY_REQUEST });
    try {
      const response = await ProfileService.addCandidateHobby(hobbyData);
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_HOBBY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.ADD_CANDIDATE_HOBBY_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateCandidateHobby = (hobbyData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.UPDATE_CANDIDATE_HOBBY_REQUEST });
    try {
      const response = await ProfileService.updateCandidateHobby(hobbyData);
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_HOBBY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_HOBBY_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const deleteCandidateHobby = (hobbyId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.DELETE_CANDIDATE_HOBBY_REQUEST });
    try {
      const response = await ProfileService.deleteCandidateHobby(hobbyId);
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_HOBBY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.DELETE_CANDIDATE_HOBBY_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Info person profile

export const fetchCandidateInfo = () => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_REQUEST });
    try {
      const response = await ProfileService.fetchCandidateInfoPerso();
      // console.log('rrr',response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateCandidateInfo = (infoData) => {
  return async (dispatch) => {
    dispatch({ type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_REQUEST });
    try {
      const response = await ProfileService.updateCandidateInfoPerso(infoData);
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_FAILURE,
        payload: error.message,
      });
    }
  };
};

// ProfilePicture

export const uploadProfilePicture = (pictureData) => {
  return async (dispatch) => {
    dispatch({
      type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_REQUEST,
    });
    try {
      // console.log("updateProfilePicturesss", pictureData);
      const response = await ProfileService.updateCandidateProfilePicture(
        pictureData
      );
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_FAILURE,
        payload: error.message,
      });
    }
  };
};
